module.exports = {
  aurora_testnet: {
    chainId: 87654321,   
    chainName: 'Ninjachain Testnet',  
    nativeCurrency: {
      name: 'Ninjacoin',
      symbol: 'NINJA',
      decimals: 18,
    },
    rpcUrls:  ['https://rpc3-ninjachain-testnet.scaninja.xyz/'],
    blockExplorerUrls: ['https://scaninja.xyz/'],
  }
}